export function getItemThumbnail(item, size = 0) {
  let src = ''
  let alt = 'no image'
  let showPlaceholder = true

  if (item && item.representation_id) {
    src = `/assets/${item.id}/representation/size/${size}?_dc=${item.representation_id}`
    alt = item.filename
    showPlaceholder = false
  }

  return { src, alt, showPlaceholder }
}
