import { loginToArtstor, updatePassword } from '../services/aaa.js'
import { requestPasswordReset, validateResetRequest, resetPassword } from '../services/iac.js'

const profile = {
  namespaced: true,
  actions: {
    async loginToArtstor(context, options) {
      const email = context.rootState.user.email
      const { password } = options

      return await context.dispatch(
        'utilities/tryOrLogError',
        {
          action: async () => await loginToArtstor(email, password),
          eventBasename: 'profile_artstor_login',
          toastErrorMessage: 'Failed to authenticate with Artstor.'
        },
        { root: true }
      )
    },
    async updatePassword(context, options) {
      const { currentPassword, newPassword } = options
      return await context.dispatch(
        'utilities/tryOrLogError',
        {
          action: async () => await updatePassword(currentPassword, newPassword),
          eventBasename: 'profile_artstor_update_password',
          toastErrorMessage: 'Failed to update password.'
        },
        { root: true }
      )
    },
    requestPasswordReset(context, email) {
      return requestPasswordReset(email)
    },
    validateResetRequest(context, { email, signature }) {
      return validateResetRequest(email, signature)
    },
    resetPassword(context, { email, signature, password }) {
      return resetPassword(email, signature, password)
    }
  }
}

export default profile
