import { showToastError } from '@/utils/toast'
import { captainsLog } from '@/services/captainsLog'

const utilState = {
  namespaced: true,
  actions: {
    async tryOrLogError(
      context,
      options = {
        action: async () => new Promise(),
        toastErrorMessage: null,
        eventBasename: '',
        logData: {}
      }
    ) {
      const { action, toastErrorMessage, eventBasename, logData } = options
      const successEventName = `${eventBasename}_success`
      const errorEventName = `${eventBasename}_error`
      const attemptEventName = `${eventBasename}_attempt`

      const token = context.rootState?.user?.token
      const user = context.rootState?.user
      const institution_id = user?.institution_eid
      const user_id = user?.profile_id
      const institution = context.rootState?.institutions?.data?.find(
        institution => institution.id === user?.institution_id
      )
      const identifiers = {
        user_agent: navigator.userAgent,
        institution_id,
        user_id,
        institution_name: institution?.name
      }
      const event = {
        ...identifiers,
        ...logData
      }
      try {
        captainsLog(token, { ...event, eventtype: attemptEventName })
        const p = await action()
        captainsLog(token, { ...event, eventtype: successEventName })
        return p
      } catch (err) {
        captainsLog(token, { ...event, eventtype: errorEventName })
        if (toastErrorMessage) {
          showToastError(toastErrorMessage)
        }
        throw err
      }
    }
  }
}

export default utilState
