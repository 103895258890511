import { getV2IacSession } from '../services/session'
import Cookies from 'js-cookie'

export const findIndividualAccount = iacAuthAccounts => {
  if (!iacAuthAccounts || !Array.isArray(iacAuthAccounts)) {
    return
  }

  return iacAuthAccounts.find(account => account.type.toLowerCase() === 'individual')
}

export const findSharedShelfProfile = sessionProfiles => {
  for (let profile of sessionProfiles) {
    if (profile.role.includes('SHARED_SHELF_USER') || profile.role.includes('SHARED_SHELF_ADMIN')) {
      return profile
    }
  }
  return null
}

export const checkLicense = (licensedProducts, license, institutionUuid) => {
  if (!licensedProducts) {
    return false
  }
  const licenseItem = licensedProducts.find(item => item.product === license)
  const licenseAccounts = licenseItem?.accounts
  return licenseAccounts?.includes(institutionUuid)
}

export const setSessionCookie = async uuidCookie => {
  const session = await getV2IacSession(uuidCookie)
  if (session?.sessionTriplet) {
    const sessionTriplet = session.sessionTriplet
    const accessSessionCookie = sessionTriplet.accessSession
    const accessSessionSignatureCookie = sessionTriplet.accessSessionSignature
    const accessSessionTimedSignatureCookie = sessionTriplet.accessSessionTimedSignature
    Cookies.set('AccessSession', accessSessionCookie)
    Cookies.set('AccessSessionSignature', accessSessionSignatureCookie)
    Cookies.set('AccessSessionTimedSignature', accessSessionTimedSignatureCookie)
  }

  return session
}
