import { getUsers, createUsers, enableForum, disableForum } from './services'
import { getExistingIacSession } from '../../services/session'
import { findIndividualAccount, setSessionCookie } from '@/utils/user'

export const defaultUserSettings = () => ({
  hasValidSession: false,
  timerActive: false,
  activeUsers: []
})

const FETCH_SESSION_INTERVAL = 5 * 60 * 1000 // 5 minutes

let timeoutId = null

const userSettings = {
  namespaced: true,
  state: defaultUserSettings(),
  mutations: {
    SET_SESSION(state, status) {
      state.hasValidSession = status
    },
    RESET_STATE(state) {
      Object.assign(state, defaultUserSettings())
      clearTimeout(timeoutId)
    },
    SET_TIMER_STATE(state, status) {
      state.timerActive = status
    },
    SET_USERS(state, users) {
      state.activeUsers = users
    }
  },
  actions: {
    async setSessionCookie__internal(context) {
      return await context.dispatch(
        'utilities/tryOrLogError',
        {
          action: async () => await setSessionCookie(context.rootState.user.uuid),
          eventBasename: 'admin_set_session_cookie',
          toastErrorMessage: 'Failed to set session cookie.'
        },
        { root: true }
      )
    },
    async refreshSession(context) {
      const response = {
        data: {
          status: true
        }
      }

      const uuidCookie = context.rootState.user.uuid
      const session = await getExistingIacSession(uuidCookie)

      const individualUser = findIndividualAccount(session.authenticatedAccounts)

      if (individualUser) {
        context.commit('SET_SESSION', true)
        context.commit('SET_TIMER_STATE', true)
        context.dispatch('scheduleRefreshSession')
      } else {
        // log user out and reset state
        response.data.status = false
      }

      return response
    },
    scheduleRefreshSession(context) {
      if (!context.state.timerActive) {
        return
      }

      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(() => context.dispatch('refreshSessionInterval'), FETCH_SESSION_INTERVAL)
      return timeoutId
    },
    async refreshSessionInterval(context) {
      let session = false
      const maxTries = 2
      for (let index = 1; index <= maxTries; index++) {
        if (!context.state.timerActive) {
          return
        }

        const res = await context.dispatch('refreshSession')
        const user = res.data
        if (user.status) {
          session = true
          break
        } else if (index < maxTries) {
          await new Promise(resolve => setTimeout(resolve, 1000))
        }
      }

      if (!session) {
        context.commit('RESET_STATE')
      } else {
        context.dispatch('scheduleRefreshSession')
      }
    },
    async fetchUsers(context) {
      await context.dispatch('setSessionCookie__internal')

      try {
        const res = await context.dispatch(
          'utilities/tryOrLogError',
          {
            action: async () => await getUsers(),
            eventBasename: 'admin_load_users',
            toastErrorMessage: 'Failed to load users.'
          },
          { root: true }
        )
        context.commit('SET_USERS', res.data)
        return res
      } catch (err) {
        if (err?.response?.status === 401) {
          context.commit('RESET_STATE')
        }
      }
    },
    async createUsers(context, options) {
      await context.dispatch('setSessionCookie__internal')

      const { emails } = options
      try {
        return await context.dispatch(
          'utilities/tryOrLogError',
          {
            action: async () => await createUsers(emails),
            eventBasename: 'admin_create_users',
            toastErrorMessage: 'Failed to create users.'
          },
          { root: true }
        )
      } catch (err) {
        if (err?.response?.status === 401) {
          context.commit('RESET_STATE')
        }
      }
    },
    async enableForum(context, options) {
      await context.dispatch('setSessionCookie__internal')

      const { profileId, isAdmin } = options
      try {
        return await context.dispatch(
          'utilities/tryOrLogError',
          {
            action: async () => await enableForum(profileId, isAdmin),
            eventBasename: 'admin_enable_forum',
            toastErrorMessage: 'Failed to enable forum for user.'
          },
          { root: true }
        )
      } catch (err) {
        if (err?.response?.status === 401) {
          context.commit('RESET_STATE')
        }
      }
    },
    async disableForum(context, options) {
      await context.dispatch('setSessionCookie__internal')

      const { profileId } = options
      try {
        return await context.dispatch(
          'utilities/tryOrLogError',
          {
            action: async () => await disableForum(profileId),
            eventBasename: 'admin_disable_forum',
            toastErrorMessage: 'Failed to disable forum for user.'
          },
          { root: true }
        )
      } catch (err) {
        if (err?.response?.status === 401) {
          context.commit('RESET_STATE')
        }
      }
    }
  },
  getters: {
    forumUsers: state =>
      state.activeUsers
        .filter(user => user.ssenabled === true)
        .map(user => {
          user['ssAdmin'] = user.roles.split(',').includes('SHARED_SHELF_ADMIN')
          return user
        })
  }
}

export default userSettings
